<script setup>
import TextViewerVue from '@/components/TextViewer.vue'
import { getNotice } from '@/api/platform/community' // 공지사항 조회

import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const notice = ref(null)

const numberFormat = (num) => {
  return new Intl.NumberFormat().format(num)
}

const boardData = {
  boardName: 'notice',
  boardId: route.params.id
}

const routeReplace = () => {
  const path = route.path
  const listUrl = path.slice(0, path.lastIndexOf('/'))
  router.replace(listUrl)
}

const loadNotice = async (id) => {
  const notice = await getNotice(id)

  if (notice.code) {
    return null
  }
  if (notice.message) {
    return null
  }
  return notice
}

onMounted(async () => {
  notice.value = await loadNotice(route.params.id)
})
</script>

<template>
  <section class="wf__notice-section">
    <h2 class="wf__notice-title">유망연구실 공지사항</h2>

    <div class="divider"></div>

    <article class="wf__notice" v-if="notice">
      <h3 class="wf__community-default-title">{{ notice.title }}</h3>

      <ul class="wf__notice-desc">
        <li>{{ notice.name }}</li>
        <li>|</li>
        <li>{{ notice.createdAt.substr(0, 10) }}</li>
        <li>|</li>
        <li>조회 {{ numberFormat(notice.noticeViewCount) }}</li>
      </ul>

      <!-- <div>
        <div v-for="(file, i) in notice.embededFiles" :key="i" :file="file">
          <span class="material-icons-outlined"> file_download </span>
          <span>{{ file.fileName }}</span>
        </div>
      </div> -->

      <TextViewerVue :propsData="notice.content" :boardData="boardData" width="60%"></TextViewerVue>
    </article>

    <div v-else>결과가 없습니다.</div>

    <div class="divider"></div>

    <div>
      <span class="wf__notice-list-btn" @click="routeReplace">List</span>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__notice-section {
  display: flex;
  flex-direction: column;
  gap: 1em;

  font-size: var(--wf-text-16);

  text-align: left;
  margin: 3em 0;
}

.divider {
  height: 2px;
  background-color: var(--wf-gray-500);
  margin: 1rem 0;
}

.wf__notice {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.wf__notice-title {
  max-width: 25em;
  font-size: var(--wf-text-24);
  font-weight: 600;
}
.wf__notice-desc {
  display: flex;
  gap: 0.5em;
  color: var(--wf-secondary-color);

  margin-bottom: var(--wf-text-30);
}

.wf__notice-list-btn {
  font-size: 1em;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #fff;
  background-color: var(--wf-primary-color);
  float: right;
  cursor: pointer;
  border-radius: 0.25em;
  padding: 0.35em 1em;
}
.wf__notice-list-btn:hover {
  opacity: 0.75;
}
</style>
